import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchCategoryComponent } from '@fg-shared/search/search-category/search-category.component';
import { SearchCommunityComponent } from '@fg-shared/search/search-community/search-community.component';
import { SearchConventionComponent } from '@fg-shared/search/search-convention/search-convention.component';
import { SearchExhibitorComponent } from '@fg-shared/search/search-exhibitor/search-exhibitor.component';
import { SearchGuestComponent } from '@fg-shared/search/search-guest/search-guest.component';
import { SearchInputComponent } from '@fg-shared/search/search-input/search-input.component';
import { SearchItemComponent } from '@fg-shared/search/search-item/search-item.component';
import { SearchOrganizationComponent } from '@fg-shared/search/search-organization/search-organization.component';
import { SearchPostComponent } from '@fg-shared/search/search-post/search-post.component';
import { SearchPromoComponent } from '@fg-shared/search/search-promo/search-promo.component';
import { SearchScheduleEventComponent } from '@fg-shared/search/search-schedule-event/search-schedule-event.component';
import { SearchUserComponent } from '@fg-shared/search/search-user/search-user.component';
import { TileModule } from '@fg-shared/tile/tile.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SearchAdPartnerComponent } from './search-ad-partner/search-ad-partner.component';
import { SearchOzconventionComponent } from './search-ozconvention/search-ozconvention.component';
import { SearchSelectiveExhibitorComponent } from './search-selective-exhibitor/search-selective-exhibitor.component';
import { SearchSelectiveGuestComponent } from './search-selective-guest/search-selective-guest.component';

const searchComponents = [
  SearchScheduleEventComponent,
  SearchGuestComponent,
  SearchExhibitorComponent,
  SearchUserComponent,
  SearchCommunityComponent,
  SearchInputComponent,
  SearchPromoComponent,
  SearchPostComponent,
  SearchConventionComponent,
  SearchOzconventionComponent,
  SearchCategoryComponent,
  SearchItemComponent,
  SearchAdPartnerComponent,
  SearchOrganizationComponent,
  SearchSelectiveExhibitorComponent,
  SearchSelectiveGuestComponent
];

@NgModule({
  imports: [CommonModule, AutoCompleteModule, FormsModule, TileModule],
  declarations: searchComponents,
  exports: searchComponents,
  providers: []
})
export class SearchModule {}
