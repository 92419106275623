<div class="fg-view-container fg-view-container-bg">
  <div class="layout-padding fg-col-body">
    <div class="p-grid p-mt-3">
      <div class="input-container p-col-12 p-mt-1">
        <div class="input-cell title-font">
          <h4>SHOP SECTION</h4>
        </div>
      </div>

      <!-- Shop Toggle -->
      <div class="p-col-12">
        <div class="input-container p-col-3">
          <div class="input-cell inline-toggle">
            <label>Enable Shop
              <tooltip-button
                tooltip="Shop can be enabled or disabled here to allow or disallow the creator to sell their products.">
              </tooltip-button>
            </label>
            <p-inputSwitch name="isShopEnabled" [(ngModel)]="selectedCreator.isShopEnabled"
              (ngModelChange)="handleToggleChange('isShopEnabled')">
            </p-inputSwitch>
          </div>
        </div>
      </div>

      <!-- Schedule Toggle -->
      <div class="p-col-12">
        <div class="input-container p-col-3">
          <div class="input-cell inline-toggle">
            <label>Enable Schedule
              <tooltip-button
                tooltip="Schedule can be enabled or disabled here to allow or disallow the creator to show their events.">
              </tooltip-button>
            </label>
            <p-inputSwitch name="isScheduleEnabled" [(ngModel)]="selectedCreator.isScheduleEnabled"
              (ngModelChange)="handleToggleChange('isScheduleEnabled')">
            </p-inputSwitch>
          </div>
        </div>
      </div>

      <!-- Requests Toggle -->
      <div class="p-col-12">
        <div class="input-container p-col-3">
          <div class="input-cell inline-toggle">
            <label>Enable Requests
              <tooltip-button
                tooltip="Requests can be enabled or disabled here to allow or disallow the creator to sell their requests.">
              </tooltip-button>
            </label>
            <p-inputSwitch name="isFanRequestEnabled" [(ngModel)]="selectedCreator.isFanRequestEnabled"
              (ngModelChange)="handleToggleChange('isFanRequestEnabled')">
            </p-inputSwitch>
          </div>
        </div>
      </div>

      <!-- Community Toggle -->
      <div class="p-col-12">
        <div class="input-container p-col-3">
          <div class="input-cell inline-toggle">
            <label>Enable Community
              <tooltip-button
                tooltip="Community can be enabled or disabled here to allow or disallow the creator to show their communtities.">
              </tooltip-button>
            </label>
            <p-inputSwitch name="isCommunityEnabled" [(ngModel)]="selectedCreator.isCommunityEnabled"
              (ngModelChange)="handleToggleChange('isCommunityEnabled')">
            </p-inputSwitch>
          </div>
        </div>
      </div>

      <!-- Contest Toggle -->
      <div class="p-col-12">
        <div class="input-container p-col-3">
          <div class="input-cell inline-toggle">
            <label>Enable Contest
              <tooltip-button
                tooltip="Contest can be enabled or disabled here to allow or disallow the creator to show contests.">
              </tooltip-button>
            </label>
            <p-inputSwitch name="isContestEnabled" [(ngModel)]="selectedCreator.isContestEnabled"
              (ngModelChange)="handleToggleChange('isContestEnabled')">
            </p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
