import { ApiBase } from '@fg-services/api/models/api-base';
import { Category } from '@fg-types/category';
import { IChallenge } from '@fg-types/challenge';
import { FandomConvention } from '@fg-types/community';
import { ConventionButton } from '@fg-types/convention-button';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';
import { IConventionContest } from '@fg-types/convention-contest';
import { IConventionEvent, ITableSettings } from '@fg-types/convention-event';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { ConventionLink } from '@fg-types/convention-link';
import { ConventionPartner } from '@fg-types/convention-partner';
import {
  IConventionCelebRatingSummary,
  IConventionEventRatingSummary,
  IConventionExhibitorRatingSummary,
  IConventionRatingComment
} from '@fg-types/convention-rating';
import { AllowedService, GroupedAllowedService } from '@fg-types/convention-service';
import { IToday } from '@fg-types/convention-today.type';
import { ConventionTicketType, IConvention } from '@fg-types/convention.type';
import { DeleteResponse } from '@fg-types/delete-response';
import { GlobalGuest } from '@fg-types/global-guest';
import { IGroupedChallenge } from '@fg-types/grouped-challenge';
import { Notification } from '@fg-types/notification';
import { Organization } from '@fg-types/organization.type';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { IPost } from '@fg-types/post';
import { IPrize } from '@fg-types/prize';
import { IReport, ReportableModel, ReportStatus, ReportType } from '@fg-types/report';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ConEventsParams {
  id: string;
  lastKey?: string;
  types?: string[] | string;
  order?: string;
  scheduleOrder?:string;
  limit?: string;
  tags?: string[] | string;
  locationPrimaries?: string[] | string;
  isLivestream?: boolean;
}

export class ConventionApi extends ApiBase {
  model = 'Conventions';

  create(convention: Partial<IConvention>) {
    return this.http.post<IConvention>(this.modelBase, convention);
  }

  fandoms(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<FandomConvention>>(
      `${this.modelBase}/${id}/FandomConventions`,
      {
        params: this.setParams({
          lastKey
        })
      }
    );
  }

  ticketTypes(id: string, lastKey?: string, allTicketTypes = true) {
    return this.http.get<PaginatedResponse<ConventionTicketType>>(
      `${this.modelUri(id)}/TicketTypes`,
      { params: this.setParams({ lastKey, allTicketTypes }) }
    );
  }

  postTicketType(conventionId: string, body: Partial<ConventionTicketType>) {
    return this.http.post<ConventionTicketType>(
      `${this.modelUri(conventionId)}/TicketSauceTicketType`,
      body
    );
  }

  patchTicketType(conventionId: string, ticketId: string, changes: Partial<ConventionTicketType>) {
    return this.http.patch<ConventionTicketType>(
      `${this.modelUri(conventionId)}/TicketSauceTicketType/${ticketId}`,
      changes
    );
  }

  deleteTicketSauceTicketType(conventionId: string, ticketId: string) {
    return this.http.delete<DeleteResponse>(
      `${this.modelUri(conventionId)}/TicketSauceTicketType/${ticketId}`
    );
  }

  getConventionById(id: string) {
    return this.http.get<IConvention>(`${this.modelBase}/${id}`, {
      params: this.setParams({ stats: true })
    });
  }

  updateConvention(id: string, convention: Partial<IConvention>) {
    return this.http.patch<IConvention>(`${this.modelBase}/${id}`, convention);
  }

  getConventions(
    query?: any,
    lastKey?: string,
    filterPartnerStatus?: string,
    allConventions = false
  ) {
    const params = this.setParams({
      ...query,
      lastKey,
      filterPartnerStatus,
      allConventions,
      order: 'name',
      limit: 20
    });
    const uri = query?.query ? `${this.apiBase}/Search/Conventions` : this.modelBase;
    return this.http.get<PaginatedResponse<IConvention>>(uri, { params });
  }

  // Services
  getConventionAllowedServices(id: string): Observable<GroupedAllowedService> {
    return this.http
      .get<PaginatedResponse<AllowedService>>(`${this.modelBase}/${id}/Services`)
      .pipe(
        map(res => {
          const groupedServices: GroupedAllowedService = {};
          res.items.forEach(svc => {
            if (
              svc?.service &&
              (!svc.service.mobile || svc.service.referenceModelName === 'Convention.exhibitHall')
            ) {
              groupedServices[svc.service.referenceModelName] = svc.access;
            }
          });
          return groupedServices;
        })
      );
  }

  // Celebs
  getConventionGuests(
    id: string,
    lastKey?: string,
    query?: string,
    limit?: number,
    types?: string[],
    orderBy?: string
  ) {
    return this.http.get<PaginatedResponse<IConventionCelebrity>>(
      `${this.modelBase}/${id}/Celebrities`,
      {
        params: this.setParams({
          lastKey,
          query,
          limit,
          types,
          orderBy
        })
      }
    );
  }

  deleteConventionGuests(id: string, guestId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Celebrities/${guestId}`);
  }

  putConventionCeleb(id: string, guestId: string, body: Partial<GlobalGuest>) {
    return this.http.put<IConventionCelebrity>(
      `${this.modelBase}/${id}/Celebrities/${guestId}`,
      body
    );
  }

  updateGuestInformationLocally(id: string, guestId: string, body: Partial<IConventionCelebrity>) {
    return this.http.patch<IConventionCelebrity>(
      `${this.modelBase}/${id}/Celebrities/${guestId}`,
      body
    );
  }

  // Exhibitors
  getConventionExhibitors(
    id: string,
    lastKey?: string,
    query?: string,
    limit?: number,
    exhibitorTypes?: string[]
  ) {
    return this.http.get<PaginatedResponse<ConventionExhibitor>>(
      `${this.modelBase}/${id}/ConventionExhibitors`,
      {
        params: this.setParams({ lastKey, query, limit, exhibitorTypes })
      }
    );
  }

  deleteConventionExhibitors(id: string, exhibitorId: string) {
    return this.http.delete<DeleteResponse>(
      `${this.modelBase}/${id}/ConventionExhibitors/${exhibitorId}`
    );
  }

  putConventionExhibitor(id: string, exhibitorId: string) {
    return this.http.put<ConventionExhibitor>(
      `${this.modelBase}/${id}/ConventionExhibitors/${exhibitorId}`,
      {}
    );
  }

  updateExhibitorInformationLocally(
    id: string,
    exhibitorId: string,
    updatedExhibitor: Partial<ConventionExhibitor>
  ) {
    return this.http.put<ConventionExhibitor>(
      `${this.modelBase}/${id}/ConventionExhibitors/${exhibitorId}`,
      updatedExhibitor
    );
  }

  // Buttons
  getConventionCustomButtons(
    id: string,
    params: {
      lastKey?: string;
      isLive?: boolean;
      orderBy?: string;
      adminStatus?: 'publicOnly' | 'all';
    }
  ) {
    return this.http.get<PaginatedResponse<ConventionButton>>(`${this.modelBase}/${id}/Buttons`, {
      params: this.setParams({
        ...params,
        orderBy: params.orderBy || 'order ASC'
      })
    });
  }

  // Todays
  getConventionTodays(id: string, type: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IToday>>(`${this.modelBase}/${id}/Todays`, {
      params: this.setParams({
        lastKey,
        type
      })
    });
  }

  getConventionTodaysNow(id: string) {
    return this.http.get<IToday>(`${this.modelBase}/${id}/Todays/now`);
  }

  createConventionToday(id: string, conventionToday: Partial<IToday>) {
    return this.http.post<IToday>(`${this.modelBase}/${id}/Todays`, conventionToday);
  }

  updateConventionToday(id: string, todayId: string, conventionToday: Partial<IToday>) {
    return this.http.patch<IToday>(`${this.modelBase}/${id}/Todays/${todayId}`, conventionToday);
  }

  deleteConventionToday(id: string, todayId: string) {
    return this.http.delete(`${this.modelBase}/${id}/Todays/${todayId}`);
  }

  // Events
  getConventionEvents({
    id,
    types,
    order,
    scheduleOrder,
    lastKey,
    limit,
    tags,
    locationPrimaries,
    isLivestream
  }: ConEventsParams) {
    return this.http.get<PaginatedResponse<IConventionEvent>>(`${this.modelBase}/${id}/Events`, {
      params: this.setParams({
        types,
        order,
        scheduleOrder,
        lastKey,
        limit,
        tags,
        locationPrimaries,
        isLivestream
      })
    });
  }

  getEventsRecording({
    id,
    lastKey,
    order
  }: ConEventsParams) {
    return this.http.get<PaginatedResponse<IConventionEvent>>(`${this.modelBase}/${id}/Events`, {
      params: this.setParams({
        lastKey,
        isLivestream:true,
        order
      })
    });
  }

  // Events
  getRecordings({
    id,
    lastKey
  }: {id: string;lastKey: string}) {
    return this.http.get<any>(`${this.apiBase}/Records/Convention=${id}`, {
      params: this.setParams({
        lastKey
      })
    });
  }

  getAllRecodings({
    id,
    limit
  }: {id: string;limit: number}) {
    return this.http.get<any>(`${this.apiBase}/Records/Convention=${id}`, {
      params: this.setParams({
        limit
      })
    });
  }

  deleteRecordings(id: string) {
    return this.http.delete<any>(`${this.apiBase}/Records/${id}`);
  }

  updateConventionEvents(id: string, eventId: string, conventionEvent: Partial<IConventionEvent>) {
    return this.http.patch<IConventionEvent>(
      `${this.modelBase}/${id}/Events/${eventId}`,
      conventionEvent
    );
  }

  createConventionEvents(id: string, conventionEvent: Partial<IConventionEvent>) {
    return this.http.post<IConventionEvent>(`${this.modelBase}/${id}/Events`, conventionEvent);
  }

  deleteConventionEvents(id: string, eventId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Events/${eventId}`);
  }

  updateTableSettings(id: string, conventionEventId: string, body: ITableSettings) {
    return this.http.patch<ITableSettings>(
      `${this.modelBase}/${id}/Events/${conventionEventId}/Tables/settings`,
      body
    );
  }

  addConEventTables(id: string, conventionEventId: string, body: { number: number }) {
    return this.http.post<IConventionEvent>(
      `${this.modelBase}/${id}/Events/${conventionEventId}/Tables`,
      body
    );
  }

  deleteConEventTable(id: string, conventionEventId: string, tableId: string) {
    return this.http.delete<DeleteResponse>(
      `${this.modelBase}/${id}/Events/${conventionEventId}/Tables/${tableId}`
    );
  }

  // Challenges
  getConventionChallenges(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IChallenge>>(`${this.modelBase}/${id}/Challenges`, {
      params: this.setParams({
        lastKey
      })
    });
  }

  getConventionGroupedChallenges(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IGroupedChallenge>>(
      `${this.modelBase}/${id}/GroupedChallenges`,
      {
        params: this.setParams({
          lastKey
        })
      }
    );
  }

  updateConventionChallenges(
    id: string,
    challengeId: string,
    conventionChallenge: Partial<IChallenge>
  ) {
    return this.http.patch<IChallenge>(
      `${this.modelBase}/${id}/Challenges/${challengeId}`,
      conventionChallenge
    );
  }

  createConventionChallenges(id: string, conventionChallenge: Partial<IChallenge>) {
    return this.http.post<IChallenge>(`${this.modelBase}/${id}/Challenges`, conventionChallenge);
  }

  deleteConventionChallenges(id: string, challengeId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Challenges/${challengeId}`);
  }

  // Contests
  getConventionContests(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IConventionContest>>(
      `${this.modelBase}/${id}/Contests`,
      {
        params: this.setParams({ lastKey })
      }
    );
  }

  // Partners

  getConventionPartnersData({
    conventionId,
    partnerId,
    type
  }: {
    conventionId: string;
    partnerId?: string;
    type?: string;
  }) {
    return this.http.get<PaginatedResponse<ConventionPartner>>(
      `${this.modelBase}/${conventionId}/Partners/${partnerId}/data`,
      {
        params: this.setParams({ type })
      }
    );
  }

  getConventionPartnersByType(id: string, promoType: string, lastKey?: string, query?: string) {
    return this.http.get<PaginatedResponse<ConventionPartner>>(
      `${this.modelBase}/${id}/Partners/type/${promoType}`,
      {
        params: this.setParams({ lastKey, query })
      }
    );
  }

  updateConventionPartners(
    id: string,
    partnerId: string,
    conventionPartner: Partial<ConventionPartner>
  ) {
    return this.http.patch<ConventionPartner>(
      `${this.modelBase}/${id}/Partners/${partnerId}`,
      conventionPartner
    );
  }

  createConventionPartners(id: string, conventionPartner: Partial<ConventionPartner>) {
    return this.http.post<ConventionPartner>(`${this.modelBase}/${id}/Partners`, conventionPartner);
  }

  deleteConventionPartners(id: string, partnerId: string) {
    return this.http.delete(`${this.modelBase}/${id}/Partners/${partnerId}`);
  }

  // Prizes
  getConventionPrizes(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IPrize>>(`${this.modelBase}/${id}/Prizes`, {
      params: this.setParams({ lastKey })
    });
  }

  updateConventionPrizes(id: string, prizeId: string, conventionPrize: Partial<IPrize>) {
    return this.http.patch<IPrize>(`${this.modelBase}/${id}/Prizes/${prizeId}`, conventionPrize);
  }

  pickConventionPrizeWinner(
    id: string,
    prizeId: string,
    challengeId?: string,
    challengeGroupId?: string
  ) {
    return this.http.put<IPrize>(
      `${this.modelBase}/${id}/Prizes/${prizeId}/winner`,
      {},
      {
        params: this.setParams({ challengeId, challengeGroupId })
      }
    );
  }

  createConventionPrizes(id: string, conventionPrize: Partial<IPrize>) {
    return this.http.post<IPrize>(`${this.modelBase}/${id}/Prizes`, conventionPrize);
  }

  deleteConventionPrizes(id: string, prizeId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Prizes/${prizeId}`);
  }

  // Ratings
  getRatings(id: string, lastKey?: string, format?: string, userIds?: string) {
    return this.http.get<PaginatedResponse<IConventionEventRatingSummary>>(
      `${this.modelBase}/${id}/Events/Ratings`,
      { params: this.setParams({ lastKey, format, userIds }) }
    );
  }

  getCommentsRatings(id: string, lastKey?: string, userIds?: string) {
    return this.http.get<PaginatedResponse<IConventionRatingComment>>(
      `${this.modelBase}/${id}/Events/Ratings`,
      { params: this.setParams({ lastKey, userIds }) }
    );
  }

  getCelebritiesRatings(id: string) {
    return this.http.get<PaginatedResponse<IConventionCelebRatingSummary>>(
      `${this.modelBase}/${id}/Celebrities/Ratings`
    );
  }

  getCelebritiesRatingComments(id: string, userIds: string) {
    return this.http.get<PaginatedResponse<IConventionRatingComment>>(
      `${this.modelBase}/${id}/Celebrities/Ratings`,
      { params: this.setParams({ userIds }) }
    );
  }

  getExhibitorsRatings(id: string) {
    return this.http.get<PaginatedResponse<IConventionExhibitorRatingSummary>>(
      `${this.modelBase}/${id}/ConventionExhibitors/Ratings`
    );
  }

  getExhibitorsRatingComments(id: string, userIds: string) {
    return this.http.get<PaginatedResponse<IConventionRatingComment>>(
      `${this.modelBase}/${id}/ConventionExhibitors/Ratings`,
      { params: this.setParams({ userIds }) }
    );
  }

  // Links
  createConventionLink(id: string, conventionLink: Partial<ConventionLink>) {
    return this.http.post<ConventionLink>(`${this.modelBase}/${id}/Links`, conventionLink);
  }

  getConventionLinks(id: string) {
    return this.http.get<PaginatedResponse<ConventionLink>>(`${this.modelBase}/${id}/Links`);
  }

  updateConventionLink(id: string, linkId: string, conventionLink: Partial<ConventionLink>) {
    return this.http.patch<ConventionLink>(
      `${this.modelBase}/${id}/Links/${linkId}`,
      conventionLink
    );
  }

  deleteConventionLink(id: string, linkId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Links/${linkId}`);
  }

  // Rewards
  getSurveyAnswers(id: string, eventId: string) {
    return this.http.get<any>(`${this.modelBase}/${id}/Rewards/${eventId}surveyCSV`);
  }

  createReward(id: string, body: any): Observable<any> {
    return this.http.post(`${this.modelBase}/${id}/Rewards`, body);
  }

  getUserRewards(id: string, userId: string): Observable<any> {
    return this.http.get(`${this.modelBase}/${id}/Rewards/user/${userId}`);
  }

  // Posts
  getPosts(
    id: string,
    params: {
      postTypes: string;
      following?: boolean;
      skip?: number;
      lastKey?: string;
      paginationType?: string;
      limit?: number;
      order?: string;
      userIds?: string;
    }
  ) {
    return this.http.get<PaginatedResponse<IPost>>(`${this.modelBase}/${id}/Posts`, {
      params: this.setParams(params)
    });
  }

  // Notifications
  getScheduledNotifications(
    id: string,
    actions: string,
    lastKey?: string,
    limit?: number,
    conventionEventId?: string,
    sent?: string,
    query?: string
  ) {
    return this.http.get<PaginatedResponse<Notification>>(
      `${this.modelBase}/${id}/ScheduledNotifications`,
      {
        params: this.setParams({
          actions,
          lastKey,
          limit,
          conventionEventId,
          sent,
          query,
          order: 'scheduledDate DESC'
        })
      }
    );
  }

  // Reports
  getEventReports<T extends ReportableModel>(
    id: string,
    lastKey?: string,
    type?: ReportType[],
    status?: ReportStatus | ReportStatus[]
  ) {
    const params = this.setParams({
      lastKey,
      type,
      status
    });
    return this.http.get<PaginatedResponse<IReport<T>>>(`${this.modelBase}/${id}/Reports`, {
      params
    });
  }

  // Categories
  getCategories(id: string) {
    return this.http.get<PaginatedResponse<Category>>(`${this.modelBase}/${id}/Categories`);
  }

  addConventionCategory(id: string, categoryId: string) {
    return this.http.put<Category>(`${this.modelBase}/${id}/Categories/${categoryId}`, {});
  }

  removeConventionCategory(id: string, categoryId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Categories/${categoryId}`);
  }

  // Banned users
  getBannedConventionUsers(id: string, limit: number) {
    return this.http.get<any>(`${this.modelBase}/${id}/userBans`, {
      params: this.setParams({ limit })
    });
  }

  banUser(id: string, userId: string): Observable<any> {
    return this.http.post(`${this.modelBase}/${id}/userBans`, { userId });
  }

  unBanUser(id: string, userId: string): Observable<any> {
    return this.http.delete(`${this.modelBase}/${id}/userBans/${userId}`);
  }

  // Growtix
  refreshGrowtix(id: string): Observable<any> {
    return this.http.put(`${this.modelBase}/${id}/refreshGrowtix`, {});
  }

  deleteConvention(conventionId: string) {
    return this.http.delete<DeleteResponse>(this.modelUri(conventionId));
  }

  duplicateConvention(id: string, body: any) {
    return this.http.put<IConvention>(`${this.modelUri(id)}/duplicate`, body);
  }

  deleteAllConventionEvents(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Events/All`);
  }

  deleteAllConventionExhibitors(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/ConventionExhibitors/all`);
  }

  deleteAllConventionCelebrities(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Celebrities/all `);
  }

  refreshTicketSauce(id: string) {
    return this.http.put<any>(`${this.modelBase}/${id}/refreshTicketSauce`, {});
  }

  inviteConventionEventStreamHost(id: string, eventId: string, email: string) {
    return this.http.post<any>(`${this.modelBase}/${id}/Events/${eventId}/inviteHost`, { email });
  }

  revokeStreamHostInvite(id: string, eventId: string, email: string) {
    return this.http.post<any>(`${this.modelBase}/${id}/Events/${eventId}/revokeHost`, { email });
  }

  getOrgById(id: string) {
    return this.http.get<Organization>(`${this.modelBase}/${id}/organizations`);
  }

  setIsExhibitiorAttachedToMap(id, exhibitorId: string, payload: any) {
    return this.http.patch<any>(`${this.modelBase}/${id}/ConventionExhibitors/${exhibitorId}/attachedStatus`, payload);
  }
  
  setIsGuestAttachedToMap( id, exhibitorId: string, payload: any ) {
    return this.http.patch<any>( `${ this.modelBase }/${id}/ConventionCelebrities/${exhibitorId}/attachedStatus`, payload);
  }
}
