import { Injectable } from '@angular/core';
import { Community } from '@fg-types/community';
import { IConvention } from '@fg-types/convention.type';
import { DeleteResponse } from '@fg-types/delete-response';
import { GlobalGuest } from '@fg-types/global-guest';
import { Organization } from '@fg-types/organization.type';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { ApiBase } from './api-base';

@Injectable({
  providedIn: 'root'
})
export class GlobalGuestApi extends ApiBase {
  model = 'Celebrities';

  getGuestConventions(id: string, options: { type?: string }) {
    const params = this.setParams({ type: options.type });
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/${id}/Conventions`, {
      params
    });
  }

  getGuestSubscriptionBases(id: string) {
    return this.http.get<PaginatedResponse<IConvention>>(
      `${this.modelBase}/${id}/SubscriptionBases`
    );
  }

  getGuestCommunities(id: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/${id}/Fandoms`);
  }

  deleteGuestById(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  newGuest(guest: GlobalGuest) {
    return this.http.post<GlobalGuest>(`${this.modelBase}`, guest);
  }

  editById(guest: GlobalGuest) {
    return this.http.patch<GlobalGuest>(`${this.modelBase}/${guest.id}`, guest);
  }
  
  guestById( id: string ) {
    return this.http.get<GlobalGuest>(`${this.modelBase}/${id}`);
  }

  getOrgById(id: string) {
    return this.http.get<Organization>(`${this.modelBase}/${id}/organization`);
  }
}
