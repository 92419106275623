import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWTInterceptorService } from '@fg-services/jwtinterceptor.service';
// Cosmunity Modules
import { SharedModule } from '@fg-shared/shared.module';
// Cosmunity UI Components
import { HomeModule } from '@fg-app/backstage/home/home.module';
import { environment } from '@fg-environments/environment';
import { UserService } from '@fg-services/user.service';
import { CustomConfirmDialogModule } from '@fg-shared/custom-confirm-dialog/custom-confirm-dialog.module';
import { NgxStripeModule } from 'ngx-stripe';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChatApiModule } from '@fg-services/chat-api/chat-api.module';
import { AppSubmenuComponent } from './app-menu/app-submenu/app-submenu.component';
import { AppMenuComponent } from './app-menu/app.menu.component';
import { AppTopbarComponent } from './app-toolbar/app.topbar.component';
import { AppComponent } from './app.component';
import { GlobalServices } from './app.global-services';
import { AppRoutingModule } from './app.routes';
import { ConsoleComponent } from './console/console.component';
import { ExhibitHallReferralLinkComponent } from './exhibit-hall-referral-link/exhibit-hall-referral-link.component';
import { FaqsViewComponent } from './faqs-view/faqs-view.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { PanelModule } from 'primeng/panel';
import { ProfileComponent } from './creator-platform/cp-profile/cp-profile';
import { ColorPickerModule } from 'primeng/colorpicker';
import { OverviewComponent } from './creator-platform/cp-overview/cp-overview';
import { DropdownModule } from 'primeng/dropdown';
import { CalenderComponent } from './creator-platform/calender/calender.component';
import { TeamMembersComponent } from './creator-platform/team-members/team-members.component';
import { CreatorSettingsComponent } from './creator-platform/creator-settings/creator-settings.component';

// import { EmailComponent } from './account-setting/email/email.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    OverlayPanelModule,
    InputSwitchModule,
    CustomConfirmDialogModule,
    CardModule,
    HomeModule,
    MatIconModule,
    ChatApiModule,
    PanelModule,
    NgxStripeModule.forRoot(environment.STRIPE_PUBLISHABLE_KEY),
    ColorPickerModule,
    DropdownModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptorService,
      multi: true
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ...GlobalServices
  ],
  declarations: [
    AppComponent,
    AppMenuComponent,
    AppTopbarComponent,
    AppSubmenuComponent,
    ConsoleComponent,
    ExhibitHallReferralLinkComponent,
    FaqsViewComponent,
    ImpersonateComponent,
    EditProfileComponent,
    ProfileComponent,
    OverviewComponent,
    CalenderComponent,
    TeamMembersComponent,
    CreatorSettingsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private userService: UserService) {
    this.userService.getUserPreferences();
    this.userService.getNotificationPreferences();
  }
}
