import { Component, OnInit } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { CreatorService } from '@fg-services/dummy.service';
import { UiService } from '@fg-services/ui.service';
import { CreatorData } from '@fg-types/creator';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component( {
  selector: 'creator-settings',
  templateUrl: './creator-settings.component.html',
  styleUrls: [ './creator-settings.component.scss' ]
} )
export class CreatorSettingsComponent implements OnInit {
  selectedCreator: Partial<CreatorData>;
  creatorSettings: any;

  constructor(
    private uiService: UiService,
    private apiCallsInProgress: ApiCallsInProgress,
    private apiService: ApiService,
    private cp: CreatorService
  ) { }

  ngOnInit(): void {
    this.cp.currentCreator$.subscribe( item => {
      this.selectedCreator = item;
      this.initializeSettings( item );
    } );
  }

  initializeSettings( item: any ) {
    this.apiCallsInProgress.addCall();

    this.apiService.creator.getCreatorTabSettings( item.id )
      .pipe(
        finalize( () => {
          this.apiCallsInProgress.reset();
        } )
      )
      .subscribe( {
        next: ( settings ) => {
          if ( settings && settings.length > 0 ) {
            this.creatorSettings = settings[ 0 ];
            this.selectedCreator = {
              ...this.selectedCreator,
              isShopEnabled: this.creatorSettings.isShopEnabled,
              isScheduleEnabled: this.creatorSettings.isScheduleEnabled,
              isFanRequestEnabled: this.creatorSettings.isFanRequestEnabled,
              isCommunityEnabled: this.creatorSettings.isCommunityEnabled,
              isContestEnabled: this.creatorSettings.isContestEnabled
            };
          }
        },
        error: ( error ) => {
          this.uiService.toast( {
            severity: 'error',
            summary: 'Error:',
            detail: 'Failed to load settings.'
          } );
          console.error( 'Failed to load settings:', error );
        }
      } );
  }

  handleToggleChange( changedProperty: string ) {
    const postPayload = {
      creatorId: this.selectedCreator.id,
      isShopEnabled: this.selectedCreator.isShopEnabled,
      isScheduleEnabled: this.selectedCreator.isScheduleEnabled,
      isFanRequestEnabled: this.selectedCreator.isFanRequestEnabled,
      isCommunityEnabled: this.selectedCreator.isCommunityEnabled,
      isContestEnabled: this.selectedCreator.isContestEnabled
    };

    const patchPayload = {
      [ changedProperty ]: this.selectedCreator[ changedProperty ]
    };

    this.apiCallsInProgress.addCall();

    const shouldPost = !this.creatorSettings?.id;

    const operation = shouldPost
      ? this.apiService.creator.postCreatorTabSettings( { body: postPayload } )
      : this.apiService.creator.patchCreatorTabSettings( this.creatorSettings.id, { body: patchPayload } );

    operation
      .pipe(
        finalize( () => {
          if ( shouldPost ) {
            this.initializeSettings( this.selectedCreator );
          }
          this.apiCallsInProgress.reset();
        } ),
        catchError( ( error: any ) => {
          const operationType = this.creatorSettings ? 'PATCH' : 'POST';
          const errorMessage = error.status === 403
            ? 'You do not have permission to perform this action.'
            : 'There was an error saving the changes.';
          this.uiService.toast( {
            severity: 'error',
            summary: `Error during ${ operationType }:`,
            detail: errorMessage
          } );
          console.error( `API Error during ${ operationType }:`, error );
          return throwError( () => error );
        } )
      )
      .subscribe( {
        next: ( result ) => {
          this.uiService.toast( {
            severity: 'success',
            summary: 'Success:',
            detail: 'Changes successfully saved.'
          } );
        },
        error: () => { }
      } );
  }
}
