import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';

@Component({
  selector: 'search-selective-guest',
  templateUrl: './search-selective-guest.component.html',
  styleUrls: ['./search-selective-guest.component.scss']
})
export class SearchSelectiveGuestComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Input() createNewText: string;
  @Output() addGuest = new EventEmitter<IConventionCelebrity>();
  @Input() title = 'Search Guest';
  @Output() queryChange = new EventEmitter<string>();
  filteredGuests: (IConventionCelebrity | string)[] = [];
  params: any;
  query: string = '';
  guest = '';
  allGuests: IConventionCelebrity[] = [];

  constructor( private apiService: ApiService ) { }

  ngOnInit(): void {
    this.loadCelebrities();
  }

  loadCelebrities() {
    this.loader().subscribe(
      (res: any) => {
        this.allGuests = res.items;
        this.filteredGuests = [ ...this.allGuests ];
      },
      (error) => {
        console.error('Error loading celebrities:', error);
      }
    );
  }

  filter( params?: AutoCompleteEvent ) {
    if ( params ) {
      this.query = params.query.toLowerCase();
      this.queryChange.emit( this.query );

      this.filteredGuests = this.allGuests.filter( exhibitor =>
        exhibitor.firstName.toLowerCase().includes( this.query )
      );

      if ( !this.filteredGuests ) {
        this.filteredGuests.push( this.createNewText );
      }
    }
  }

  loader() {
    return this.apiService.conventions.getConventionGuests(
      this.conventionId,
      null,
      null,
      1000
    );
  }

}
