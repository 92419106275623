<!-- <div class="input-container">
    <div class="input-cell">
        <label *ngIf="title">{{ title }}</label>
        <span class="input-flex flex-right">
            <p-autoComplete [disabled]="disabled" [ngModelOptions]="{ standalone: true }" [(ngModel)]="guest"
                [suggestions]="filteredGuests" placeholder="Type to search a Guest" field="firstName"
                (completeMethod)="filter($event)" (onSelect)="addGuest.emit($event); guest = ''"
                styleClass="autocomplete-tile complete-input" inputStyleClass="complete-input">
                <ng-template let-item pTemplate="item">
                    <guest-tile *ngIf="item.id; else createNew" [data]="item" hoverActive="1"></guest-tile>
                    <ng-template #createNew>{{ item }}</ng-template>
                </ng-template>
            </p-autoComplete>
        </span>
    </div>
</div> -->
<div class="input-container">
  <div class="input-cell">
    <label *ngIf="title">{{ title }}</label>
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="guest"
        [suggestions]="filteredGuests"
        placeholder="Type to search an Guest"
        field="name"
        (completeMethod)="filter($event)"
        (onSelect)="addGuest.emit($event); guest = ''"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
      >
        <ng-template let-item pTemplate="item">
          <guest-tile
            *ngIf="item.id; else createNew"
            [data]="item"
            hoverActive="1"
          ></guest-tile>
          <ng-template #createNew>{{ item }}</ng-template>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>
