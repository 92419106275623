import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { CreatorService } from '@fg-services/dummy.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { CreatorRole } from '@fg-services/navigation-context/segments/creator-segment';
import { OrganizationRole } from '@fg-services/navigation-context/segments/organization-segment';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { CanDeactivateComponent } from '@fg-shared/helpers/can-deactivate-component';
import { CmMedia } from '@fg-types/cm-media';
import { Creator, CreatorData } from '@fg-types/creator';
import { Organization } from '@fg-types/organization.type';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
  selector: 'cp-profile',
  templateUrl: './cp-profile.html',
  styleUrls: ['./cp-profile.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  newCreator: CreatorData = {
    id: '',
    firstName: '',
    posts: [],
    publishCreator: false
  };
  defaultItem: CreatorData = {
    id: '',
    firstName: '',
    posts: []
  };
  creatorsList: CreatorData[] = [];
  org: Organization = {
    name: '',
    theme: undefined,
    headerMediaId: '',
    headerMedia: undefined,
    officialAccountId: '',
    resetSettings: undefined,
    replacementValues: undefined,
    mailchimpListId: '',
    notificationSettings: undefined,
    links: undefined,
    versionSettings: undefined,
    id: '',
    createdAt: '',
    updatedAt: ''
  };
  colorsTemplate = [
    {
      label: 'PRIMARY COLOR',
      prop: 'primaryColor'
    },
    {
      label: 'SECONDARY COLOR',
      prop: 'secondaryColor'
    },
    {
      label: 'TEXT COLOR',
      prop: 'textColor'
    }
  ];
  topBarIsHidden: boolean;
  sideMenuIsHidden: boolean;
  newOne: boolean;
  lastKey: string;
  ngOnInit(): void {
    if (this.route.snapshot.url.join('/').includes('new')) {
      this.newCreator = this.defaultItem;
    } else {
      this.cp.currentCreator$.subscribe(item => {
        this.newCreator = item;
      });
    }
    this.cp.userCreatorsList.subscribe(items => {
      this.creatorsList = items;
    });
    this.userService.loadRoles().subscribe();
  }
  ngOnDestroy(): void {}

  isDirty: boolean = false;
  canDeactivate() {
    return new Observable<boolean>(observer => {
      // this.loadOrganization();
      if (!this.isDirty) {
        observer.next(true);
        observer.complete();
      } else {
        this.confirmationService.confirm({
          header: 'Warning',
          message: 'Are you sure you want to leave without saving your changes?',
          acceptLabel: 'SAVE CHANGES',
          rejectLabel: 'DISCARD CHANGES',
          accept: () => {
            this.onSave();
            observer.next(false);
            observer.complete();
          },
          reject: (reason: string) => {
            this.newCreator?.id &&
              this.api.creator.getCreatorById(this.newCreator?.id).subscribe(item => {
                this.cp.updateCurrentCreator(item);
                this.api.users
                  .userCreators(this.user.id, this.lastKey)
                  .subscribe(({ items, lastKey }) => {
                    const creatorslist = this.lastKey ? [...items] : items;
                    this.lastKey = lastKey;
                    this.cp.updateUserCreatorsList(creatorslist);
                  });
              });
            observer.next(reason !== 'close');
            observer.complete();
          }
        });
      }
    });
  }
  constructor(
    private apiCallsInProgress: ApiCallsInProgress,
    private router: Router,
    private route: ActivatedRoute,
    private uiService: UiService,
    private confirmationService: ConfirmationService,
    private api: ApiService,
    private userService: UserService,
    private cp: CreatorService
  ) {}

  get user() {
    return this.userService.user;
  }
  createNewOrganization(name: string) {
    let defaultColor = {
      primaryColor: '#00e5da',
      secondaryColor: '#192a2e',
      searchTextColor: '#323232',
      labelColor: '#ffffff',
      accentColor: '#541c9c',
      headerColor: '#192a2e',
      headerLabelColor: '#ffffff',
      iconColor: '#00e5da'
    };

    const changes: Partial<Organization> = {
      theme: defaultColor,
      name: name
    };
    this.apiCallsInProgress.addCall();
    this.userService.getRolesAsObservable().subscribe(
      roles => {
        roles.isOrganizationAdmin
          ? forkJoin([
              this.api.modelUser.add({
                modelName: 'Creator',
                modelId: this.newCreator.id,
                userId: this.user.id,
                role: CreatorRole.CreatorAdmin
              }),
            ]).subscribe(res => {
              this.updateCreator()
              this.userService.roles.isCreatorAdmin = true;
            })
          : this.api.organizations
              .create(changes)
              .pipe(
                switchMap(response1 => {
                  return forkJoin([
                    this.api.organizations.putOrgCreators(response1.id, this.newCreator?.id),
                    this.api.modelUser.add({
                      modelName: 'Organization',
                      modelId: response1.id,
                      userId: this.user.id,
                      role: OrganizationRole.OrgAdmin || OrganizationRole.Admin
                    })
                  ]);
                })
              )
              .subscribe(result => {
                forkJoin([
                  this.api.modelUser.add({
                    modelName: 'Creator',
                    modelId: this.newCreator.id,
                    userId: this.user.id,
                    role: CreatorRole.CreatorAdmin
                  }),
                  this.api.creator.updateCreatorById(this.newCreator),
                ]).subscribe(() => {
                  this.apiCallsInProgress.reset();
                  this.uiService.toast({
                    severity: 'success',
                    summary: 'Success:',
                    detail: 'Organization is created with the name of Creator.'
                  });
                });
              });
      },
      () => {
        this.uiService.toast({
          severity: 'error',
          detail: `There was an error creating the Organization.`
        });
        this.apiCallsInProgress.reset();
      }
    );
  }

  loadOrganization() {
    if (this.newCreator?.id) {
      this.api.creator.getCreatorOrg(this.newCreator).subscribe(
        response => {
          console.log('organization already created');
        },
        error => {
          this.createNewOrganization(this.newCreator?.firstName);
        }
      );
    }
  }

  updateCreator(){
    this.apiCallsInProgress.addCall()
    this.api.creator.updateCreatorById(this.newCreator).subscribe(item => {
      this.uiService.toast({
        severity: 'success',
        summary: 'Success:',
        detail: 'Changes successfully saved.'
      });
      this.cp.updateCurrentCreator(this.newCreator);
      this.apiCallsInProgress.reset();
    })
  }

  onSave() {
    this.newCreator.user = this.user;
    this.newCreator.userId = this.user?.id;
    this.apiCallsInProgress.addCall();
    this.userService.getRolesAsObservable().subscribe(roles => {
      roles.isCreatorAdmin
        ? this.updateCreator()
        : this.createNewOrganization(this.newCreator?.firstName);
    });
    this.isDirty = false;
  }

  onDelete(id) {
    this.confirmationService.confirm({
      header: 'Warning',
      message: 'Are you sure you want to Delete creator?',
      acceptLabel: 'Delete',
      rejectLabel: 'cancel',
      accept: () => {
        // this.creatorService.removeCreator(id);
        this.api.creator.deleteCreatorById(id).subscribe(item => {
          this.newCreator = item;
        });
      }
    });
    this.cp.updateCurrentCreator(this.newCreator);
    this.cp.updateUserCreatorsList(this.creatorsList);
  }

  setImage(image?: CmMedia, field?: string) {
    if (field === 'headerMedia') {
      this.newCreator.headerMedia = image ? image : null;
      this.newCreator.headerMediaId = image ? image?.id : null;
    } else {
      this.newCreator.profileMedia = image ? image : null;
      this.newCreator.profileMediaId = image ? image?.id : null;
    }
  }

  handlePublishCreator() { 
    this.newCreator.publishCreator = !this.newCreator.publishCreator;
    this.updateCreator();
  }
}
